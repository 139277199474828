.custom-select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-select__menu-list::-webkit-scrollbar-thumb {
  background: #9a9cab;
  border-radius: 4px;
}

.custom-select__menu-list::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 10px;
  margin-block: 2.5px;
}
